import { Button, Modal } from "antd";
import axios from "axios";
import React from "react";

const UpdatePostsModal = (props: any) => {
  const style = { display: "flex", alignItems: "center", marginBottom: "10px", background:'#404040',color:'white' };
  const { isUpdatePostModalOpen, selectedUser, setIsUpdatePostModalOpen } =
    props;
  async function handleUpdate() {
    console.log("Pulling posts", selectedUser);
    const url = `https://dev.thesearchbubble.com/postupdates`;
    // const url = `http://localhost:3001/postupdates`;

    const resThumbnail = axios.post(
      url,
      { username: selectedUser, from: "content" },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    setIsUpdatePostModalOpen(false);
  }


  async function handleUpload() {
    console.log("Pulling stories", selectedUser);
    const url = `https://dev.thesearchbubble.com/storyupdates`;
    // const url = `http://localhost:3001/storyupdates`;

    const resThumbnail = axios.post(
      url,
      { username: selectedUser, from: "content" },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    setIsUpdatePostModalOpen(false);
  }
  function handleCancel() {
    setIsUpdatePostModalOpen(false);
  }
  return (
    <div>
      {" "}
      <Modal
        title={
          <>
            Update Data for{" "}
            <a
              href={`https://www.searchbubble.com/${selectedUser}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedUser}
            </a>{" "}
          </>
        }
        open={isUpdatePostModalOpen}
        // onOk={handleOk}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <div style={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Upload Stories:</label>
            <Button onClick={handleUpload}>Upload</Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label style={{ marginRight: "10px" }}>Update Posts:</label>
            <Button onClick={handleUpdate}>Update</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdatePostsModal;
