import React, { Fragment, useState } from "react";
import { Button, Carousel, Input, Modal, Popconfirm, Space } from "antd";
import {
  BsArchiveFill,
  BsPlusCircleFill,
  BsFillBookmarkStarFill,
  BsFillBookmarkDashFill,
  BsFillTrashFill,
} from "react-icons/bs";
import useUpdateTags from "./useUpdateTags";
import useUpdatePostStatus from "./useUpdatePostStatus";

import "./feedTab.css";
import TagsBox from "../../../Components/TagsBox/TagsBox";
import {
  changeAdSequence,
  getAdsFeedLength,
  orderAdSequence,
  reduceAdSequence,
  removePostasAd,
} from "../../../SupabaseServices/ContentDatabase";
import { HiCheck, HiPencil } from "react-icons/hi";
import useUpdatePermalink from "./useUpdatePermalink";
import Caption from "./Caption";
import useUpdateShoplink from "./useUpdateShoplink";
import useUpdateTaggedlink from "./useUpdateTaggedLink";
import { MdOutlineFileDownload } from "react-icons/md";
import ProductDetails from "./ProductDetails";
import useDeletePosts from "../../../Utils/CustomHooks/useDeletePosts";

const ContentTable = ({
  usernames,
  activeKey,
  data,
  filter,
  setLimitError,
  refetchData,
  userIds,
  currentPage,
}: any) => {
  console.log(usernames, userIds);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState("");
  const [selectedPost, setSelectedPost] = useState("");

  const { mutate: deletePostPermanently } = useDeletePosts();

  const handleDragStart = (e: any, index: any) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e: any, index: any) => {
    e.preventDefault();
  };

  const handleDrop = async (
    e: any,
    index: number,
    post_id: any,
    username: any
  ) => {
    const targetIndex = index;
    const sourceIndex = parseInt(e.dataTransfer.getData("text/plain"));
    orderAdSequence(sourceIndex, targetIndex, post_id).then(() => {
      refetchData();
    });
  };

  const { mutate: updateTags } = useUpdateTags();
  const { mutate: updatePermalink } = useUpdatePermalink();
  const { mutate: updateShoplink } = useUpdateShoplink();
  const { mutate: updateTaggedlink } = useUpdateTaggedlink();

  const { mutate: updatePostStatus } = useUpdatePostStatus();

  function removeTag(
    tagArr: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    const idx = tagArr.indexOf(tag);

    tagArr.splice(idx, 1);
    updateTags({
      currentPage,
      activeKey,
      tagString: tagArr.toString(),
      post_id,
      pageIndex,
      index,
      filter,
      usernames,
      action: "remove",
      tag,
    });
  }

  function submitTag(
    e: any,
    tagArray: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      const resultString = modifiedArray.toString();

      let arrString = tagArray
        ? [`${resultString}`, ...tagArray.split(",")].toString()
        : `${resultString}`;

      updateTags({
        currentPage,
        activeKey,
        tagString: arrString,
        post_id,
        pageIndex,
        index,
        filter,
        usernames,
        action: "submit",
        tag,
      });
      // });

      e.target.value = "";
    }
  }
  function archivePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "archived",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      usernames,
      userIds,
    });
    console.log("update");
    refetchData();
  }
  function activePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "active",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      usernames,
      userIds,
    });
  }
  function adPost(
    post_id: string,
    pageIndex: number,
    postIndex: number,
    username: any
  ) {
    getAdsFeedLength(usernames).then((adCount: any) => {
      if (adCount + 1 <= 2) {
        updatePostStatus({
          newPostStatus: "active,ad",
          postId: post_id,
          activeKey,
          pageIndex,
          postIndex,
          filter,
          usernames,
          userIds,
        });
        changeAdSequence(post_id, adCount);
        setLimitError(false);
      } else {
        setLimitError(true);
      }
    });
  }

  async function removeFromAds(post_id: any, username: any) {
    removePostasAd(post_id).then(() => {
      refetchData();
      getAdsFeedLength(usernames).then((adCount: any) => {
        reduceAdSequence(username);
        if (adCount + 1 <= 2) {
          setLimitError(false);
        }
      });
    });
  }
  const handleButtonClick = (e: any, postId: any, post: any) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Button clicked");

    setIsProductModalOpen(true);
    setSelectedPostId(postId);
    setSelectedPost(post);
  };
  const ActionButtons = ({
    postId,
    pageIndex,
    index,
    postIndex,
    username,
    permalink,
    shoplink,
    postDate,
    taggedlink,
    platform,
    type,
    url,
    post,group
  }: any) => {
    const [newPermalink, setNewPermalink] = useState(permalink);
    const [isEditPermalink, setIsEditPermalink] = useState(false);
    const [newShoplink, setNewShoplink] = useState(shoplink);
    const [isEditShoplink, setIsEditShoplink] = useState(false);
    const [newTaggedlink, setNewTaggedlink] = useState(taggedlink);
    const [isEditTaggedlink, setIsEditTaggedlink] = useState(false);
    function saveTaggedlink(e: any) {
      e.preventDefault();
      if (e.keyCode === 13) {
        let taggedLink = e.target.value;
        updateTaggedlink({
          currentPage,
          activeKey,
          tagString: taggedLink,
          post_id: postId,
          pageIndex,
          index,
          filter,
          usernames,
        });
      }
    }
    function saveShoplink(e: any) {
      e.preventDefault();
      if (e.keyCode === 13) {
        let shoplink = e.target.value;

        let encodedUrl = "";

        if (shoplink) {
          const match = shoplink.match(/u=([^&]+)/);
          if (match && match[1]) {
            encodedUrl = match[1];
          } else {
            encodedUrl = shoplink;
          }
        } else {
          encodedUrl = "";
        }

        var decodedUrl = decodeURIComponent(encodedUrl);
        const url = new URL(decodedUrl);
        url.searchParams.delete('fbclid'); // Remove fbclid parameter
    
        // Get the URL without fbclid
        const cleanedUrl = url.toString();
        console.log("cleaned",cleanedUrl)
        updateShoplink({
          currentPage,
          activeKey,
          tagString: cleanedUrl,
          post_id: postId,
          pageIndex,
          index,
          filter,
          usernames,
        });
      }
    }

    function savePermalink(e: any) {
      e.preventDefault();
      if (e.keyCode === 13) {
        let permalink = e.target.value;
        updatePermalink({
          currentPage,
          activeKey,
          tagString: permalink,
          post_id: postId,
          pageIndex,
          index,
          filter,
          usernames,
        });
      }
    }
    async function downloadPost(
      post_id: any,
      platform: any,
      type: any,
      url: any
    ) {
      let urlArrays = url?.split(",");
      for (let [i, u] of urlArrays.entries()) {
        try {
          const response = await fetch(u);
          if (!response.ok) {
            throw new Error("Failed to download file");
          }

          const blob = await response.blob();

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${post_id}_${i}`;
          link.click();

          window.URL.revokeObjectURL(link.href);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      }
    }
    function deletePost(post_id: string, pageIndex: number, index: number,group:any) {
      deletePostPermanently({
        postId: post_id,
        activeKey,
        pageIndex,
        postIndex: index,
        filter,
        user_id: userIds[0],
        username:usernames[0]
      });
    }
    return (
      <>
        {new Date(postDate).getFullYear() > 2000 ? (
          <p className="creator-feed-content-date">
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              day: "numeric",
              month: "long",
            }).format(new Date(postDate))}
          </p>
        ) : (
          ""
        )}
        {activeKey !== "archived" ? (
          <Button
            type="default"
            icon={<BsArchiveFill className="bookmark-fill" />}
            onClick={() => {
              archivePost(postId, pageIndex, index);
            }}
          >
            Archive
          </Button>
        ) : (
          <Button
            type="default"
            icon={<BsPlusCircleFill className="bookmark-fill" />}
            onClick={() => {
              activePost(postId, pageIndex, index);
            }}
          >
            Active
          </Button>
        )}

        {activeKey !== "archived" || activeKey !== "active,ad" ? (
          <Button
            icon={<BsFillBookmarkStarFill className="bookmark-fill" />}
            onClick={() => {
              adPost(postId, pageIndex, index, username);
            }}
            disabled={!(postIndex === "active")}
          >
            {postIndex === "active" ? "Add to Ads" : "Added to Ads"}
          </Button>
        ) : activeKey === "active,ad" ? (
          <Button
            icon={<BsFillBookmarkDashFill className="bookmark-fill" />}
            onClick={() => {
              removeFromAds(postId, username);
            }}
          >
            Remove from Ads
          </Button>
        ) : (
          ""
        )}
        {activeKey !== "archived" || activeKey !== "active,ad" ? (
          <>
            {permalink ? (
              <>
                {isEditPermalink ? (
                  <input
                    type="text"
                    placeholder="Enter permalink here"
                    onKeyUp={(e: any) => {
                      savePermalink(e);
                    }}
                    onChange={(e: any) => setNewPermalink(e.target.value)}
                    value={newPermalink}
                  />
                ) : (
                  <p className="break-word">
                    {permalink}
                    <HiPencil onClick={() => setIsEditPermalink(true)} />
                  </p>
                )}
              </>
            ) : (
              <input
                type="text"
                placeholder="Enter permalink here"
                onKeyUp={(e: any) => {
                  savePermalink(e);
                }}
              />
            )}

            {shoplink ? (
              <>
                {isEditShoplink ? (
                  <input
                    type="text"
                    // id="tagInput"
                    // enterKeyHint="enter"
                    placeholder="Enter shoplink here"
                    // className="tagInput"
                    onKeyUp={(e: any) => {
                      saveShoplink(e);
                    }}
                    onChange={(e: any) => setNewShoplink(e.target.value)}
                    value={newShoplink}
                  />
                ) : (
                  <p className="break-word">
                    {shoplink}
                    <HiPencil onClick={() => setIsEditShoplink(true)} />
                  </p>
                )}
              </>
            ) : (
              <input
                type="text"
                placeholder="Enter shoplink here"
                onKeyUp={(e: any) => {
                  saveShoplink(e);
                }}
              />
            )}
            {taggedlink ? (
              <>
                {isEditTaggedlink ? (
                  <input
                    type="text"
                    // id="tagInput"
                    // enterKeyHint="enter"
                    placeholder="Enter taggedLink here"
                    // className="tagInput"
                    onKeyUp={(e: any) => {
                      saveTaggedlink(e);
                    }}
                    onChange={(e: any) => setNewTaggedlink(e.target.value)}
                    value={newTaggedlink}
                  />
                ) : (
                  <p className="break-word">
                    {taggedlink}
                    <HiPencil onClick={() => setIsEditTaggedlink(true)} />
                  </p>
                )}
              </>
            ) : (
              <input
                type="text"
                placeholder="Enter taggedLink here"
                onKeyUp={(e: any) => {
                  saveTaggedlink(e);
                }}
              />
            )}
            <Button
              icon={<MdOutlineFileDownload className="bookmark-fill" />}
              onClick={() => downloadPost(postId, platform, type, url)}
            >
              Download
            </Button>
            <Button
              onClick={(e) => handleButtonClick(e, post.post_id, post)}

              // onClick={(e:any) => {
              //   e.preventDefault()
              //   setIsProductModalOpen(true);
              //   setSelectedPostId(postId);
              //   setSelectedPost(post);
              // }}
            >
              {post.product_image &&
              post.product_image !== "" &&
              post.product_name &&
              post.product_name != ""
                ? "Added"
                : "Product"}
            </Button>
            <Popconfirm
              title="Are you sure you want to permanently delete this post?"
              onConfirm={() => deletePost(postId, pageIndex, index,group)}
              okText="Yes"
              cancelText="No"
              overlayStyle={{ maxWidth: "240px" }}
            >
              <Button
                type="primary"
                danger
                icon={<BsFillTrashFill className="iconSize" />}
                style={{ gap: "1px" }}
              >
                Delete Post
              </Button>
            </Popconfirm>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  console.log("data", data);
  return (
    <>
      {data.pages && data.pages[0].length ? (
        <div className="feed-tab-posts-table">
          {data?.pages.map((group: any, pageIndex: number) => (
            <Fragment key={pageIndex}>
              {group.map((image: any, index: number) => {
                return (
                  <div
                    className={`feed-tab-post-row ${
                      activeKey === "active,ad"
                        ? "feed-tab-post-row-grab"
                        : null
                    }`}
                    key={index}
                    draggable={activeKey === "active,ad" ? true : false}
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDrop={(e) =>
                      handleDrop(e, index, image.post_id, image.username)
                    }
                  >
                    <div className="feed-tab-row-media">
                      <div className="feed-tab-row-media-wrapper">
                        {image && image.media_url ? (
                          <>
                            {image.media_url.split(",").length > 1 &&
                            image.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <Carousel
                                dotPosition="bottom"
                                className="feed-tab-media-carousel"
                              >
                                {image.media_url
                                  .split(",")
                                  .map((media_url: any, index: any) => (
                                    <React.Fragment>
                                      {media_url.includes("video") ? (
                                        <video
                                          className="feed-tab-media mh-300"
                                          autoPlay
                                          playsInline
                                          muted
                                          loop
                                          poster={
                                            image.thumbnail &&
                                            image.thumbnail.split(",").length &&
                                            image.thumbnail.split(",")[index]
                                              ? image.thumbnail.split(",")[
                                                  index
                                                ]
                                              : "/assets/dummy_video.png"
                                          }
                                          // src={media_url}
                                        >
                                          {/* <source src={media_url} /> */}
                                        </video>
                                      ) : (
                                        <img
                                          className="feed-tab-media mh-300"
                                          src={media_url}
                                          alt="post"
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Carousel>
                            ) : (
                              <>
                                {image.media_url.includes("video") ? (
                                  <video
                                    className="feed-tab-media"
                                    autoPlay
                                    playsInline
                                    muted
                                    loop
                                    poster={
                                      image.thumbnail
                                        ? image.thumbnail
                                        : "/assets/dummy_video.png"
                                    }
                                    src={image.media_url}
                                  >
                                    <source src={image.media_url} />
                                  </video>
                                ) : (
                                  <img
                                    className="feed-tab-media"
                                    src={image.media_url}
                                    alt="post"
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <img
                            className="feed-tab-media"
                            src={
                              image.thumbnail
                                ? image.thumbnail
                                : "/assets/dummy_video.png"
                            }
                            alt="post"
                          />
                        )}
                      </div>
                      <div className="feed-tab-row-actions-mobile">
                        <ActionButtons
                          postIndex={image.index}
                          postId={image.post_id}
                          pageIndex={pageIndex}
                          index={index}
                          username={image.username}
                          postDate={image.post_date}
                          taggedLink={image.tagged_link}
                          platform={image.platform}
                          type={image.type}
                          url={image.media_url}
                          post={image}
                          group={group}
                        />
                      </div>
                    </div>
                    <div className="feed-tab-row-tags">
                      <div className="search-container-tagInput">
                        <input
                          type="text"
                          id="tagInput"
                          enterKeyHint="enter"
                          placeholder="Enter tags here"
                          className="tagInput"
                          onKeyUp={(e: any) => {
                            submitTag(
                              e,
                              image.tagg,
                              e.target.value,
                              image.post_id,
                              pageIndex,
                              index
                            );
                          }}
                        />
                      </div>
                      <div className="tag-box">
                        {image.tagg ? (
                          <TagsBox
                            tagsArray={image.tagg.split(",")}
                            removeTag={(tag: string) => {
                              removeTag(
                                image.tagg.split(","),
                                tag,
                                image.post_id,
                                pageIndex,
                                index
                              );
                            }}
                          />
                        ) : (
                          <p className="noTags">No Tags Added yet!</p>
                        )}
                      </div>
                      {image.tags_api ? (
                        <>
                          <div className="api-tag">
                            {image.tags_api ? (
                              <>
                                API Tags :-
                                <TagsBox
                                  tagsArray={image.tags_api.split(",")}
                                  removeTag={(tag: string) => {
                                    removeTag(
                                      image.tags_api.split(","),
                                      tag,
                                      image.post_id,
                                      pageIndex,
                                      index
                                    );
                                  }}
                                />
                              </>
                            ) : (
                              <p className="noTags">No Tags generated</p>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="tag-box mt-8">
                        {image.caption ? (
                          <Caption caption={image.caption} />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="feed-tab-actions">
                      <ActionButtons
                        postIndex={image.index}
                        postId={image.post_id}
                        pageIndex={pageIndex}
                        index={index}
                        username={image.username}
                        permalink={image.permalink}
                        shoplink={image.shop_link}
                        postDate={image.post_date}
                        taggedlink={image.tagged_link}
                        platform={image.platform}
                        type={image.type}
                        url={image.media_url}
                        post={image}
                        group={group}

                      />
                    </div>
                  </div>
                );
              })}
            </Fragment>
          ))}
        </div>
      ) : (
        <p className="">
          {activeKey === "archived" ? (
            <i>
              There are no Archived posts. All pieces of content are currently
              marked as Active which means they will show up in search results,
              if relevant.
            </i>
          ) : activeKey === "active,ad" ? (
            <i>No posts added as ad.</i>
          ) : (
            ""
          )}
        </p>
      )}
      <ProductDetails
        isProductModalOpen={isProductModalOpen}
        setIsProductModalOpen={setIsProductModalOpen}
        username={usernames[0]}
        user_id={userIds?.[0]}
        post_id={selectedPostId}
        post={selectedPost}
      />
    </>
  );
};

export default ContentTable;
