import React, { useState } from "react";
import { Button, Input, Pagination, Select, Space } from "antd";
import NecUpdate from "./NecUpdate";
import { updateIncludeUser } from "../../../SupabaseServices/UserDatabase";
import { supabase } from "../../../Config/SupabaseClient";

const { Search } = Input;

const CreatorPagination = ({ userData , fetchUsers,
    setSelectedUser,
    showResetModal,
    setIsModalOpen,setIsUpdatePostModalOpen}: any) => {
  const [filteredData, setFilteredData] = useState(userData);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Set the page size
  const [searchTerm, setSearchTerm] = useState("");
const [localSelectedCreator,setLocalSelectedCreator]=useState("")
  // Calculate the data to display for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredData?.slice(startIndex, endIndex);

  // Handle search logic
  const handleSearch = (value: string) => {
    setSearchTerm(value);
    const filtered = userData?.filter((user: any) =>
      user.username.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page after search
  };
  async function resetAnalytics(user: any) {
    setSelectedUser(user);
    showResetModal();
  }

  // Handle pagination change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleChange = async (e: any, username: any, toggle: any) => {
    e.preventDefault();

    await updateIncludeUser(username, toggle);
    fetchUsers();
    if(toggle){
    setLocalSelectedCreator(username)
    alert(`${username} selected`);
    }else{
      setLocalSelectedCreator("")
    }

  };
  console.log("dfdg",localSelectedCreator)
  const showModal = (user: any) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };
  const showUpdatePostsModal = (user: any) => {
    setSelectedUser(user);
    setIsUpdatePostModalOpen(true);
  };
  function SubscriptionType(props: any) {
    const { selectedUser, subscription } = props;

    const handleChange = async (value: string) => {
      console.log(`selected ${value}`);
      const { data } = await supabase
        .from("user")
        .update({ subscription_type: value })
        .eq("username", selectedUser);
      alert("Subscription updated");
    };
    return (
      <Space wrap>
        <Select
          defaultValue={subscription ? subscription : "Select Plan"}
          style={{ width: 120 }}
          onChange={handleChange}
          options={[
            { value: "basic", label: "Basic" },
            { value: "pro", label: "Pro" },
          ]}
        />
      </Space>
    );
  }
  return (
    <div>
      {/* Search bar */}
      <Search
        placeholder="Search by username"
        onSearch={handleSearch}
        enterButton
        style={{ marginBottom: 16 }}
        value={searchTerm}
        allowClear
        onChange={(e) => handleSearch(e.target.value)}
      />

      <table className="table">
        <thead>
          <tr>
            <th>Usernames</th>
            <th>Global View</th>
            <th>Subscription Type</th>
            <th>Reset Analytics</th>
            <th>Upload Content</th>
            <th>Update</th>


          </tr>
        </thead>
        <tbody>
          {paginatedData?.map((user: any, index: any) => (
            <tr key={index}>
              <td className="column">
                <p style={{ fontWeight: "500" }}>
                  {user.username}
                  <NecUpdate username={user.username} table={true} />
                </p>
              </td>
              <td>
                {user.global_view === true || localSelectedCreator===user.username ? (
                  <Button
                    type="primary"
                    style={{ backgroundColor: "green" }}
                    onClick={(e: any) =>
                      handleChange(e, user.username, false)
                    }
                  >
                    Exclude
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="primary-color"
                    onClick={(e: any) =>
                      handleChange(e, user.username, true)
                    }
                  >
                    Include
                  </Button>
                )}
              </td>
              <td>
                <SubscriptionType
                  selectedUser={user.username}
                  subscription={user.subscription_type}
                />
              </td>
              <td>
                <Button
                  type="primary"
                  className="primary-color"
                  onClick={() => resetAnalytics(user.username)}
                >
                  Reset
                </Button>
              </td>
              <td>
                <Button
                  type="primary"
                  className="primary-color"
                  onClick={() => showModal(user.username)}
                >
                  Upload content
                </Button>
              </td>
              <td>
                <Button
                  type="primary"
                  className="primary-color"
                  onClick={() => showUpdatePostsModal(user.username)}
                >
                  Update Post
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination component */}
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredData?.length}
        onChange={handlePageChange}
        style={{ marginTop: 16 }}
      />
    </div>
  );
};

export default CreatorPagination;
